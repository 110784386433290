/* Utility */
.cursor-pointer:hover {
  cursor: pointer;
}
.z-index-99 {
  z-index: 99 !important;
}

/* Navbar */
#navbar-bars--btn {
  background-image: url('./_assets/bishop.JPG');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.navbar {
  color: white;
  padding: 0em 2em !important;
  opacity: 1 !important;
  text-align: right;
}

.navbar--contact {
  background: #4f8ee1d4;
}

.navbar--item {
  color: white;
  text-decoration: none;
  transition: transform 0.2s;
}

.navbar--active,
.navbar--item:hover {
  color: yellow;
  cursor: pointer;
  transform: translate(5px, 0px) !important;
}

.navbar--icon {
  color: yellow;
}

.navbar--container {
  right: 12px;
  z-index: 99;
  position: fixed;
}

/* Header */
#header {
  top: 0;
  position: fixed;
  background-color: black;
}

#header__icons {
  z-index: 10000;
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: flex-start;
}

.header__icon {
  color: white;
}

.header__icon:hover {
  color: yellow;
  cursor: pointer;
}

.header--container {
  left: 0px; 
  z-index: 99;
  position: fixed; 
}

/* Footer */
#footer {
  bottom: 0;
  position: fixed;
  background-color: black;
}

/* About */
#about {
  height: 100%;
  padding-bottom: 10em;
}

#about__timeline {
  opacity: 0;
  display: none;
}

#about__short,
#about__long {
  opacity: 0;
  display: none;
  padding-left: 15em;
  padding-right: 15em;
}

.about__restart {
  opacity: 0;
}

.about__restart:hover {
  color: yellow;
  cursor: pointer;
}

.about__choice,
.about__item {
  opacity: 0;
  list-style-type: none;
}

.about__item:hover {
  cursor: pointer;
  color: yellow;
  list-style-type: disc;
}

.vertical-timeline-element-date {
  color: white;
}

/* Contact */
#contact {
  opacity: 0;
  height: 100vh;
  background-image: url('./_assets/bishop2.JPG');
  background-size: cover;
}

.contact__form {
  opacity: 0;
  padding: 2em;
  background-color: rgba(255, 255, 255, 0.9);
}

.contact__btn {
  background: forestgreen !important;
  width: 200px;
  height: 42px;
}

/* Work */
#work {
  opacity: 0;
}

/* Resume */
#resume {
  height: 100vh;
  opacity: 0;
}

/* Projects */
#projects {
  width: 100% !important;
  height: 100% !important;
  padding-bottom: 200px;
}

#project__cards {
  /* left: -19vw; */
  width: 140vw;
  opacity: 0;
  display: flex;
  position: relative;
  transition: opacity 1s;
  align-items: center;
}

.project__click__me {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project__card {
  top: 1em;
  width: 30vw;
  height: 50vh;
  margin: 0.5em;
  filter: grayscale(1);
  position: relative;
  transition: filter 0.2s, top 0.5s, width 0.5s, height 0.5s;
  background: white;
  border-radius: 1.5em;
}

.project__card--first,
.project__card--last {
  top: 2em;
  width: 25vw;
  height: 45vh;
}

.project__card--active,
.project__card:hover {
  top: 0em;
  width: 35vw;
  height: 55vh;
  cursor: pointer;
  filter: grayscale(0);
}

.project__card--show {
  top: 0em;
  width: 100% !important;
  height: 100% !important;
  filter: grayscale(0);
}

#project__arrows {
  width: 100%;
  height: 100px;
  bottom: 0;
  display: flex;
  position: fixed;
  justify-content: center;
  z-index: 99;
  background-color: black;
}

.project__arrow {
  color: white;
  opacity: 0.5;
  transition: opacity 0.5s;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.project__arrow:hover {
  cursor: pointer;
  opacity: 1;
}

/* Work */
#work {
  height: 100vh;
}
.thumbnail__width {
  width: 35px !important;
}
.original__class--logo img{
  width: 50% !important;
}

/* Home */
#home {
  opacity: 0;
  height: 100%;

  background-image: url('./_assets/bishop.JPG');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#home__details--container {
  color: white;
  height: 700vh;
  background: url('./_assets/bishop.JPG') no-repeat fixed center;
  background-size: cover;
}

.home__details--background {
  opacity: 0;
  background-color: black;
}

.home__details {
  top: 20vh;
  opacity: 0;
  position: sticky;
  position: -webkit-sticky;
}

.home__title {
  color: red;
  height: 100vh;
  cursor: default !important;
  font-size: 125px;
  text-align: center;
  transition: font-size 0.2s, height 0.2s;
}

.home__scroll {
  top: -10em;
  opacity: 0;
  display: flex;
  position: relative;
  justify-content: center;
}

.home__scroll--btn {
  color: white !important;
  background-color: red;
  padding: 0.5em;
  transition: transform 0.5s, font-size 0.2s;
}

.home__scroll--btn:hover {
  color: red !important;
  cursor: pointer;
  font-size: 20px;
  background-color: white;
}

.home__continue--btn {
  color: white !important;
  border: 1px solid red;
  z-index: 1000;
  padding: 0.5em;
  position: relative;
  transition: transform 0.5s, font-size 0.2s;
}

.home__parallax--1 {
  background-image: url('./_assets/bishop.JPG');
}
.home__parallax--2 {
  background-image: url('./_assets/jtree.jpg');
}
.home__parallax--1,
.home__parallax--2 {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.home__img--fade-sticky,
.home__img--fade {
  width: 100%;
  height: 1000px;
  position: absolute;
  background: black;
}

.home__img--fade-sticky {
  position: sticky !important;
}

@media only screen and (max-width: 991px) {
  #navbar-bars--btn {
    background-size: 100vh;
    background-attachment: unset;
  }
  #home {
    background-attachment: unset;
  }
  #home__details--container {
    background: unset
  }
  .home__details {
    top: 7em;
  }
  .home__title {
    font-size: 15vw;
  }
  .home__parallax--1,
  .home__parallax--2 {
    background-attachment: unset;
  }
  .contact__form {
    margin-top: 3em;
    padding: 2em;
    background-color: rgba(255, 255, 255, 0.9);
  }
  .contact__btn {
    width: 100%;
    height: 50px;
  }
  #about__short,
  #about__long {
    padding-left: 2em;
    padding-right: 2em;
  }
  .vertical-timeline-element-date {
    color: black;
    opacity: 1 !important;
  }
}